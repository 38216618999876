var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"visible":_vm.showModal,"title":"Enviar mensagens","no-close-on-backdrop":"","hide-header":true,"hide-footer":true,"size":"lg"}},[_c('div',{staticClass:"row",staticStyle:{"justify-content":"right"}},[_c('div',{staticClass:"float-right"},[_c('img',{staticClass:"close",attrs:{"src":"img/x-icon.svg"},on:{"click":function($event){return _vm.hideModal(false)}}})])]),_c('div',{staticClass:"row"},[_c('h4',{staticStyle:{"padding-left":"20px"}},[_vm._v("Escolha uma mensagem")]),_c('div',{staticClass:"col-lg-12 order-lg-2"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card-mensagem mb-4",class:_vm.clicked1 && 'selected',on:{"click":function($event){$event.preventDefault();_vm.mensagem = '667c661ff1e66a001255c216';
            _vm.clicked1 = true;
            _vm.clicked2 = false;
            _vm.clicked3 = false;
            _vm.clicked4 = false;
            _vm.clicked5 = false;}}},[_c('p',[_vm._v("@primeironome! Sabia que sua assinatura está prestes a vencer e você ficará sem a proteção para sua bike? Não corra esse risco! Fale comigo e eu te mando as melhores condições para a sua renovação.")])]),_c('div',{staticClass:"card-mensagem mb-4",class:_vm.clicked2 && 'selected',on:{"click":function($event){$event.preventDefault();_vm.mensagem = '667c661ff1e66a001255c215';
            _vm.clicked1 = false;
            _vm.clicked2 = true;
            _vm.clicked3 = false;
            _vm.clicked4 = false;
            _vm.clicked5 = false;}}},[_c('p',[_vm._v("Opa, @primeironome! Aqui é a @atendente, do seguro Bike Registrada. Espero que você esteja bem! Estou entrando em contato para informar que chegou a hora de renovar a sua proteção, e tenho uma OFERTA EXCLUSIVA para você!")])]),_c('div',{staticClass:"card-mensagem mb-4",class:_vm.clicked3 && 'selected',on:{"click":function($event){$event.preventDefault();_vm.mensagem = '667c661ff1e66a001255c214';
            _vm.clicked1 = false;
            _vm.clicked2 = false;
            _vm.clicked3 = true;
            _vm.clicked4 = false;
            _vm.clicked5 = false;}}},[_c('p',[_vm._v("Fala, @primeironome! Está chegando a hora de renovar o seu seguro, e nós preparamos uma condição muito especial para ti!")])]),_c('div',{staticClass:"card-mensagem mb-4",class:_vm.clicked4 && 'selected',on:{"click":function($event){$event.preventDefault();_vm.mensagem = '649d84862d1bd70012bd463f';
            _vm.clicked1 = false;
            _vm.clicked2 = false;
            _vm.clicked3 = false;
            _vm.clicked4 = true;
            _vm.clicked5 = false;}}},[_c('p',[_vm._v("Fala, @PRIMEIRONOME! Espero que esteja tudo ótimo por aí. Aqui é a Bia, do Seguro Bike Registrada. Passando só para informar que tivemos um contratempo e não conseguimos efetuar a renovação da sua bike @modelo. Dessa forma, a sua proteção está PENDENTE. Mas não tem problemas! Você pode regularizar agora a sua proteção através do cartão de crédito ou, se preferir, via Pix. Basta clicar no seu link personalizado: Link 🚨E vale lembrar que, caso regularize sua assinatura via cartão de crédito e marque a opção \"Usar este cartão de crédito em cobranças futuras\", você habilita a renovação automática no cartão informado :) Restando alguma dúvida ou precisando de algo é só responder a essa mensagem! Forte abraço e ótimas pedaladas 🚴‍♀️")])]),_c('div',{staticClass:"card-mensagem mb-4",class:_vm.clicked5 && 'selected',on:{"click":function($event){$event.preventDefault();_vm.mensagem = '6499af2beb236e0012000011';
            _vm.clicked1 = false;
            _vm.clicked2 = false;
            _vm.clicked3 = false;
            _vm.clicked4 = false;
            _vm.clicked5 = true;}}},[_c('p',[_vm._v("⚠️ IMPORTANTE ⚠️ O seguro da sua bike está pendente. Entre em contato imediatamente com seu especialista bike 🚴🏻‍♀️")])])])]),_c('div',{staticClass:"row pl-5 pr-5 pb-2"},[_c('b-button',{staticClass:"btn-block",staticStyle:{"border-radius":"50px"},attrs:{"disabled":!_vm.clicked1 && !_vm.clicked2 && !_vm.clicked3 && !_vm.clicked4 && !_vm.clicked5,"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.enviarTemplate.apply(null, arguments)}}},[_vm._v(" Enviar mensagem")])],1)])])]),(_vm.loading)?_c('Loading',{attrs:{"criandoSeguro":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }